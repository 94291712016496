import isNumeric from '@/utils/isNumeric'
import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'

export default {
  mixins: [allowNumbersOnlyMixin],
  props: {
    value: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    validationRules: { type: Object, required: true },
    doseDeviationValidationRules: {
      type: Object,
      required: false,
      default: null,
    },
    defaultInitialDose: {
      type: Object,
      required: false,
      default: null,
    },
    lastPrescribedDoseValue: { type: Number, required: false },
  },
  computed: {
    validateInput() {
      if (!this.value) return []
      return this.validationErrors(this.value)
    },
    maxInputLength() {
      const end = this.validationRules.endIncluded
        ? this.validationRules.end
        : this.validationRules.end - this.validationRules.stepSize

      return end.toString().length + 1
    },
    doseWarnings() {
      if (!this.value || this.hasErrors) return []

      const number = Number(this.value)
      const warningArray = new Array()
      const warningDose = this.validationRules.warn

      if (number > warningDose) {
        warningArray.push(`You added a dose higher than ${warningDose}.`)
      }

      if (
        this.doseDeviationValidationRules &&
        this.doseDeviationValidationRules?.enabled &&
        this.lastPrescribedDoseValue
      ) {
        const difference =
          number > this.lastPrescribedDoseValue
            ? number - this.lastPrescribedDoseValue
            : this.lastPrescribedDoseValue - number

        if (
          difference >=
          (this.doseDeviationValidationRules.upper ||
            this.doseDeviationValidationRules.lower)
        ) {
          warningArray.push(`The prescribed dose is not as expected.`)
        }
      }

      if (this.defaultInitialDose && this.defaultInitialDose?.enabled) {
        const defaultInitialDoseValue = this.defaultInitialDose.value
        if (number < defaultInitialDoseValue) {
          warningArray.push(
            'The prescribed dose is not as expected per titration guideline.'
          )
        }
      }

      return warningArray
    },
    hasErrors() {
      return this.validationErrors(this.value).length > 0
    },
    hasWarnings() {
      return this.doseWarnings.length > 0
    },
  },
  methods: {
    validationErrors(value) {
      if (value && !isNumeric(value)) {
        return ['The value must be a number']
      }

      const number = Number(value)

      const begin = this.validationRules.beginIncluded
        ? this.validationRules.begin
        : this.validationRules.begin + this.validationRules.stepSize

      if (number < begin) return ['The prescribed dose is too small.']

      const end = this.validationRules.endIncluded
        ? this.validationRules.end
        : this.validationRules.end - this.validationRules.stepSize

      if (number > end)
        return [`You added a dose higher than ${end}. Please correct.`]

      if (number % this.validationRules.stepSize !== 0)
        return [
          `The dose must be a multiplicative of ${this.validationRules.stepSize}.`,
        ]

      return []
    },
  },
}
