<template>
  <div data-testid="bolus-dose-section">
    <v-row>
      <v-col> Prescribe initial <strong>mealtime</strong> doses </v-col>
    </v-row>

    <v-row>
      <v-col sm="3" class="pr-2">
        <DoseInput
          :value="prescription.bolus_breakfast.value"
          @input="onInput($event, 'bolus_breakfast')"
          @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_VALUE')"
          @toggled-active="onToggle($event, 'bolus_breakfast')"
          :validation-rules="getValidationRules('bolus_breakfast')"
          data-testid="input-bolus-breakfast"
          :is-togglable="true"
          :deactivated="!prescription.bolus_breakfast.enabled"
          :required-text="`${$t('time-point.bolus_breakfast')} is required`"
          :dosing-display-unit="dosingDisplayUnit('bolus_breakfast')"
          :default-initial-dose="getDefaultInitialDose('bolus_breakfast')"
        >
          {{ $t('time-point.bolus_breakfast') }}
        </DoseInput>
      </v-col>

      <v-col sm="3" class="px-2">
        <DoseInput
          :value="prescription.bolus_lunch.value"
          @input="onInput($event, 'bolus_lunch')"
          @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_VALUE')"
          @toggled-active="onToggle($event, 'bolus_lunch')"
          :validation-rules="getValidationRules('bolus_lunch')"
          data-testid="input-bolus-lunch"
          :is-togglable="true"
          :deactivated="!prescription.bolus_lunch.enabled"
          :required-text="`${$t('time-point.bolus_lunch')} is required`"
          :dosing-display-unit="dosingDisplayUnit('bolus_lunch')"
          :default-initial-dose="getDefaultInitialDose('bolus_lunch')"
        >
          {{ $t('time-point.bolus_lunch') }}
        </DoseInput>
      </v-col>

      <v-col sm="3" class="px-2">
        <DoseInput
          :value="prescription.bolus_dinner.value"
          @input="onInput($event, 'bolus_dinner')"
          @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_VALUE')"
          @toggled-active="onToggle($event, 'bolus_dinner')"
          :validation-rules="getValidationRules('bolus_dinner')"
          data-testid="input-bolus-dinner"
          :is-togglable="true"
          :deactivated="!prescription.bolus_dinner.enabled"
          :required-text="`${$t('time-point.bolus_dinner')} is required`"
          :dosing-display-unit="dosingDisplayUnit('bolus_dinner')"
          :default-initial-dose="getDefaultInitialDose('bolus_dinner')"
        >
          {{ $t('time-point.bolus_dinner') }}
        </DoseInput>
      </v-col>

      <v-col sm="3" class="pl-2">
        <DoseInput
          :value="prescription.bolus_extra_prescription.value"
          @input="onInput($event, 'bolus_extra_prescription')"
          @change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_EXTRA_VALUE')"
          @toggled-active="onToggle($event, 'bolus_extra_prescription')"
          :validation-rules="getValidationRules('bolus_extra_prescription')"
          :dosing-display-unit="dosingDisplayUnit('bolus_extra_prescription')"
          :default-initial-dose="
            getDefaultInitialDose('bolus_extra_prescription')
          "
          data-testid="input-bolus-extra"
          :is-togglable="true"
          :deactivated="!prescription.bolus_extra_prescription.enabled"
          :required-text="`${$t(
            'time-point.bolus_extra_prescription'
          )} is required`"
        >
          {{ $t('time-point.bolus_extra_prescription') }}
        </DoseInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-input :error-messages="bolusCountError" hide-details />
        <div
          v-for="(item, index) in bolusCountError"
          :key="index"
          class="error--text"
        >
          {{ item }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoseInput from './_DoseInput'
import initialPrescriptionMixin from './initialPrescriptionMixin'
export default {
  name: 'BolusInitialDose',
  mixins: [initialPrescriptionMixin],
  components: { DoseInput },
  computed: {
    numberOfActiveBolus() {
      return (
        Number(this.prescription.bolus_breakfast.enabled) +
        Number(this.prescription.bolus_lunch.enabled) +
        Number(this.prescription.bolus_dinner.enabled) +
        Number(this.prescription.bolus_extra_prescription.enabled)
      )
    },
    bolusCountError() {
      if (this.numberOfActiveBolus < 2)
        return ['At least 2 bolus doses should be active for the patient.']

      return []
    },
  },
}
</script>

<style scoped></style>
