<template>
  <div class="dose-input-component">
    <FormLabel :class="{ disabled: isDeactivated }">
      <slot />
    </FormLabel>
    <v-text-field
      :maxlength="maxInputLength"
      placeholder=" "
      outlined
      :value="value"
      @input="handleInput($event)"
      @change="$emit('change', $event)"
      class="v-input__control--align-text-right rounded-lg"
      :class="{ 'v-input--has-state warning--text': hasWarnings }"
      hide-details="auto"
      :rules="doseRules"
      :error-messages="doseErrors"
      inputmode="numeric"
      :disabled="isDeactivated"
      ref="input"
      data-testid="dose-input-field"
      @keypress="allowNumbersOnly($event)"
      onPaste="return false"
      :filled="isDeactivated"
    >
      <template #append> {{ dosingDisplayUnit }} </template>
    </v-text-field>
    <small
      v-for="(warning, index) in doseWarnings"
      :key="`warning-${index}`"
      class="d-flex justify-end input-msg input-msg--warning mt-2"
      data-testid="titration-warning-small"
    >
      {{ warning }}
    </small>

    <v-checkbox
      v-if="isTogglable"
      v-model="isDeactivated"
      label="Not applicable"
      dense
      hide-details
      data-testid="dose-input-toggle-switch"
      class="small-checkbox mt-0"
      color="secondary"
      tabindex="-1"
    />
  </div>
</template>

<script>
import Vue from 'vue'

import doseInputMixin from '@/components/mixins/dose-input/doseInputMixin'
import FormLabel from '../shared/FormLabel.vue'
import isNumeric from '@/utils/isNumeric'

export default Vue.extend({
  name: 'DoseInput',
  components: { FormLabel },
  mixins: [doseInputMixin],
  props: {
    doseOk: { type: Boolean, default: null },
    isTogglable: { type: Boolean, default: false },
    deactivated: { type: Boolean, default: false },
    validationRules: { type: Object, required: true },
    requiredText: { type: String, required: true },
    dosingDisplayUnit: { type: String, required: true },
  },
  data() {
    return {
      doseRules: [v => this.deactivated || !!v || this.requiredText],
      isDeactivated: this.deactivated,
    }
  },
  watch: {
    isDeactivated(newVal) {
      this.$refs.input.reset()
      this.$emit('input', null)
      this.$emit('toggled-active', !newVal)
    },
  },
  computed: {
    doseErrors() {
      if (!this.value || this.isDeactivated) return []

      return this.validateInput
    },
  },
  methods: {
    handleInput($event) {
      const targetValue = $event

      if (targetValue === '') {
        this.$emit('update:doseOk', false)
      } else if (targetValue && !isNumeric(targetValue)) {
        this.$emit('update:doseOk', false)
      } else {
        const isDoseOk = this.validationErrors(targetValue).length === 0
        this.$emit('update:doseOk', isDoseOk)
      }

      this.$emit('input', targetValue)
    },
  },
  created() {
    if (
      this.defaultInitialDose &&
      this.defaultInitialDose?.enabled &&
      !this.deactivated
    ) {
      this.handleInput(this.defaultInitialDose.value.toString())
    }
  },
})
</script>

<style lang="scss">
.dose-input-component {
  .v-input__append-inner {
    margin-top: 17px !important;
  }
}
</style>

<style lang="scss" scoped>
.small-checkbox ::v-deep .v-label {
  font-size: 14px;
  margin-top: 0;
}

.disabled {
  color: $nn-D_T60;
}
</style>
